<template>
    <div class="container-fluid edit-wrapper m-20">
        <form @submit.prevent="createProduct">
        <div class="row">
            <div class="col-md-12">
                <!-- <div class="card card-default px-4"> -->
                    <h5 class="m-20">Dodavanje artikla, opste informacije</h5>
                    <!-- <form> -->
                        <div class="row m-20">
                            <div class="col-6">
                                <div class="form-group">
                                    <label>Naziv Artikla</label>
                                    <input v-model="name" type="text" class="form-control">
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label>Sifra</label>
                                    <input v-model="productKey" type="text" class="form-control">
                                </div>
                            </div>
                        </div>
                        <div class="row m-20">
                            <div class="col-6">
                                <div class="form-group">
                                    <label>Kategorija</label>
                                    <select class="form-select" aria-label="Default select example" @change="onCategoryChange">
                                        <option 
                                            :value="category.id" 
                                            v-for="category in categories" 
                                            :key="category.id"
                                            :selected="selectedCategory && selectedCategory.id === category.id"
                                        >
                                            {{category.name}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label>Podkategorija</label>
                                    <select class="form-select" @change="onSubCategoryChange">
                                        <option 
                                            :value="category.id" 
                                            v-for="category in childCategories" 
                                            :key="category.id"
                                            :selected="selectedSubCategory && selectedSubCategory.id == category.id"
                                        >
                                            {{category.name}}
                                            {{}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row m-20">
                            <div class="col-6">
                                <div class="form-group">
                                    <label>Cena</label>
                                    <input v-model="price" type="text" class="form-control">
                                </div>
                            </div>
                        </div>
                        <div class="row m-20">
                            <div class="col-12">
                                <div class="form-group">
                                    <label>Opis</label>
                                    <textarea v-model='description' class="form-control" rows="4"></textarea>
                                </div>
                            </div>
                            <div class="col-6">
                            </div>
                        </div>
                        <!-- Specifications -->
                        <div class="col-12" v-if="selectedCategory && addedSpecifications && Object.keys(addedSpecifications).length > 0">
                            <h5 class="m-20">Dodaj specifikacije za kategoriju {{selectedCategory.name}}</h5>
                            <div class="row">
                                <div class="row">
                                    <template v-for="(specificationValue, specificationName) in addedSpecifications" :key="specificationName">
                                        <div class="form-group col-6 m-20" :class="{'error-div': specificationValidators[specificationName] && specificationValidators[specificationName].isError}">
                                            <label>{{specificationName}}</label>
                                            <input type="text" :value="specificationValue" @change="onSpecificationChange($event, specificationName)" class="form-control">
                                            <span 
                                                class="error-text"
                                                v-if="specificationValidators[specificationName] && specificationValidators[specificationName].isError"
                                            >
                                                {{specificationValidators[specificationName].errorMessage}}
                                            </span>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                        <!-- Photographs -->
                        <h5 class="m-20">Dodavanje artikla - fotografije</h5>
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group m-20">
                                    <label>Izaberi fotografije</label>
                                    <input ref="fileInput" type="file" @change="onFileSelected" style="display: none"><br>
                                    <div style="position: relative">
                                    <input type="text" readonly class="form-control">
                                    <span @click="(images && images.length == 6) ? displayToManyImagesError() :$refs.fileInput.click()" class="add-image-span">+</span>
                                    </div>
                                    <!-- <button class="btn btn-primary" type="button" @click="$refs.fileInput.click()">Izaberi fotografiju</button> -->
                                </div>
                            </div>
                        </div>
                        <!-- <div class="row mt-2" v-if="imageUrls.length > 0">
                            <div class="col-4" style="height: 139px; width: 139px;" v-for="imageUrl of imageUrls" :key="imageUrl">
                                <img :src="imageUrl" style="width: 100%; height: 100%;" alt="">
                            </div>
                         </div> -->
                         <div class="row m-20" v-if="images.length > 0">
                         <draggable v-model="images" direction='horizontal'>
                                <div v-for="(img, index) in images" :key="img.imageUrl" style="display: inline-block; cursor: pointer; margin-right: 35px;" class="m-20">
                                    <div class="image-container">
                                        <div class="image-order">{{index + 1}}</div>
                                        <img :src="img.imageUrl" style="width: 139px; height: 139px;" alt="">
                                        <button class="btn delete-image-btn d-block" @click="removeImage(img.imageUrl)">
                                            <svg class="delete-icon"></svg>
                                            Obrisi
                                        </button>
                                    </div>
                                </div>
                        </draggable>
                         </div>
                    <!-- </form> -->
                <!-- </div> -->
            </div>
        </div>
        <button type="submit" class="btn save-btn">{{isEditMode ? 'Izmeni artikal' : 'Sacuvaj artikal'}}</button>
        <button v-if="isEditMode" type="button" class="btn add-action-btn" @click="openActionModal">Dodaj akciju</button>
        <button v-if="isEditMode && discountPrice" type="button" class="btn add-action-btn" @click="deleteAction">Obrisi akciju</button>
        </form>
        <BaseModal @confirm="saveAction" @cancel="cancelActionModal(true)" header="Dodaj akciju" v-if="isActionModalOpen">
            <div class="row">
                <div class="col-md-6 cold-sm-12 col-lg-4">
                    <div class="form-group">
                        <label>Nova cena</label>
                        <input v-model="discountPrice" type="number" class="form-control">
                    </div>
                    <div class="form-group">
                        <label>Snizenje (%)</label>
                        <input v-model="discountPrecentage" type="number" class="form-control">
                    </div>
                </div>
            </div>
        </BaseModal>
    </div>
</template>

<script>
import BaseModal from '../../../components/shared/BaseModal.vue'
import { useStore } from "vuex";
import { ref,computed, reactive, toRefs, onMounted, inject, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router'
import axios from 'axios';
import { VueDraggableNext } from 'vue-draggable-next'
export default {
    components: { draggable: VueDraggableNext, BaseModal },
    setup() {
        const route = useRoute();
        const router = useRouter();
        const store = useStore();
        const toast = inject('toast');

        const isEditMode = ref(false);
        const selectedCategory = ref(null);
        const selectedSubCategory = ref(null);
        const childCategories = ref([]);
        const addedSpecifications = reactive({});
        const specificationValidators = reactive({});
        const images = ref([]);
        const imagesToRemove = ref([]);
        const productId = ref(0);
        let isOnMountedFinished = false;
        const isActionModalOpen = ref(false);
        let oldDiscountPrice = '';
        const discountPrice = ref('');
        let oldDiscountPerecntage = ''
        const discountPrecentage = ref('');
        // basic article info bindings
        const basicArticleInfo = reactive({
            name: '',
            categoryId: 0,
            price: 0,
            description: '',
            productKey: ''
        });

        watch(route, () => {
            if(isOnMountedFinished) {
                initializeData();
            }
        })

        onMounted(() => {
            initializeData();
            isOnMountedFinished = true;
        })

        const initializeData = () => {
            productId.value = +route.params.id;
            if(!productId.value) return;
            images.value = [];
            imagesToRemove.value = [];
            selectedCategory.value = null;
            childCategories.value = [];
            
            if(productId.value !== 0) {
                isEditMode.value = true;
                axios.get(`${process.env.VUE_APP_API_URL}/api/Products/${productId.value}`)
                .then((res) => {
                    const product = res.data; 
                    discountPrice.value = product.actionPrice;
                    oldDiscountPrice = product.actionPrice;
                    discountPrecentage.value = product.actionDiscount;
                    oldDiscountPerecntage = product.actionDiscount;
                    basicArticleInfo.name = product.name;
                    basicArticleInfo.price = product.price;
                    basicArticleInfo.description = product.description;
                    basicArticleInfo.productKey = product.productKey;
                    for(let imageUrl of product.productImages) {
                        images.value.push({imageUrl: `${process.env.VUE_APP_API_URL}/${imageUrl}`});
                    }

                    if(product.characteristics) {
                        for(let chName in product.characteristics) {
                            addedSpecifications[chName] = product.characteristics[chName];
                        }
                    }

                    let category = categories.value.find(c => c.name == product.categoryName);
                    if(!category) {
                        for(let cat of categories.value) {
                            const childCat = cat.childCategories.find(c => c.name == product.categoryName);
                            if(childCat) {
                                category = cat;
                                childCategories.value =[{id: -1, name: ''}, ...cat.childCategories];
                                selectedSubCategory.value = childCat;
                                break;
                            }
                        }
                    }
                    if(category && category.id) {
                        if(!product.characteristics || product.characteristics.length === 0 ) {
                            selectCategory(category.id);
                        } else {
                            getSpecificationsForCategory(category.id, true);
                            selectedCategory.value = category;
                        }
                    } 
                })
            }
        }


        const categories = computed(() => {
            return store.getters.getCategories;
        });

        const onCategoryChange = (event) => {
            const id = event.target.value;
            selectedSubCategory.value = {id: -1, name: ''};
            selectCategory(id);
        }

        const onSubCategoryChange = (event) => {
            const id = event.target.value;
            if(id == -1) {
                selectedSubCategory.value = {id: -1, name: ''};
                return;
            }
            const selectedChildCategory = selectedCategory.value.childCategories.find(c => c.id === +id);
            selectedSubCategory.value = selectedChildCategory;
        }

        const selectCategory = (id) => {
            const newSelectedCategory = categories.value.find(c => c.id === +id);
            if(newSelectedCategory) {
                selectedCategory.value = newSelectedCategory;
                childCategories.value = [{id: -1, name: ''}, ...newSelectedCategory.childCategories];
                getSpecificationsForCategory(id);
            } else {
                selectedCategory.value = null;
                childCategories.value = [];
            }
        }

        const getSpecificationsForCategory = (categoryId, resetFlag = false) => {
            axios.get(`${process.env.VUE_APP_API_URL}/api/Categories/${categoryId}/specifications`)
            .then((res) => {
                if(!resetFlag) {
                    for(let specName in addedSpecifications) {
                        delete addedSpecifications[specName];
                    }
                }
                for(let spec of res.data) {
                    const specName = spec.name
                    specificationValidators[specName] = {...spec.validator, isError: false};
                    if(resetFlag) {
                        if(addedSpecifications[specName]) continue;
                    }
                    addedSpecifications[specName] = '';
                }
            })
        }

        const onFileSelected = (event) => {
            const image = event.target.files[0];
            const imageUrl = URL.createObjectURL(event.target.files[0]);
            images.value.push({image, imageUrl});
        }

        const createProduct = () => {
            if(!validateForm()) {
                return;
            }
            const fd = new FormData();
            fd.append('id', productId.value);
            fd.append('name', basicArticleInfo.name);
            fd.append('description', basicArticleInfo.description);
            fd.append('price', +basicArticleInfo.price);
            fd.append('productKey', basicArticleInfo.productKey);
            const categoyId = selectedSubCategory.value && selectedSubCategory.value.id > 0 ? selectedSubCategory.value.id : selectedCategory.value.id;
            fd.append('categoryId', categoyId);
            if(selectedSubCategory.value && selectedSubCategory.value.id > 0) {
                fd.append('parentCategoryId', selectedCategory.value.id);
            }

            for(let img of images.value) {
                if(img.image) {
                    fd.append("newImageUrls", "");
                } else {
                    fd.append("newImageUrls", img.imageUrl.split(process.env.VUE_APP_API_URL + '/')[1]);
                }
            }

            const preparedImages = images.value.map(image => image.image);
            for(let img of preparedImages) {
                fd.append('newImages', img);
            }

            for(let imageUrl of imagesToRemove.value) {
                fd.append('imagesToDelete', imageUrl);
            }

            for(let specName in addedSpecifications) {
                fd.append('specificationNames', specName);
                fd.append('specificationValues', addedSpecifications[specName]);
            }

            const token = localStorage.getItem('vodotermUser') && JSON.parse(localStorage.getItem('vodotermUser')).token;
            const headers = {
                headers: { Authorization: `Bearer ${token}` }
            };

            axios.post(`${process.env.VUE_APP_API_URL}/api/Products`, fd, headers)
                .then((res) => {
                    toast.success("Proizvod je uspesno kreiran");
                    if(res.data && res.data.id) {
                        if(res.data.id == productId.value) initializeData();
                        else router.push({ name: 'Edit', params: { id: res.data.id } });
                    }
                }).catch((err) => {
                    console.log('Err', err);
                    toast.error("Greska, proizvod nije kreiran");
                })
        }

        const openActionModal = () => {
            isActionModalOpen.value = true;
        }

        const saveAction = () => {
            if(!discountPrice.value || !discountPrecentage.value) {
                toast.error('Popuni novu cenu i snizenje');
                return;
            }
            const action = {productId: productId.value, newPrice: discountPrice.value, discount: discountPrecentage.value};
            const token = localStorage.getItem('vodotermUser') && JSON.parse(localStorage.getItem('vodotermUser')).token;
            const headers = {
                headers: { Authorization: `Bearer ${token}` }
            };
            axios.post(`${process.env.VUE_APP_API_URL}/api/Actions`, action, headers)
            .then(() => {
                toast.success("Dodata je akcija");
                oldDiscountPrice =  discountPrice.value;
                oldDiscountPerecntage = discountPrecentage.value;
                cancelActionModal();
            })
        }

        const deleteAction = () => {
            axios.delete(`${process.env.VUE_APP_API_URL}/api/Actions/${productId.value}`)
            .then(() => {
                toast.success("Uklonjena je akcija");
                discountPrice.value = '';
                oldDiscountPrice = '';
                discountPrecentage.value = '';
                oldDiscountPerecntage = '';
            })
        }

        const cancelActionModal = (resetActionValues = false) => {
            if(resetActionValues) {
                discountPrice.value = oldDiscountPrice;
                discountPrecentage.value = oldDiscountPerecntage;
            }
            isActionModalOpen.value = false;
        }

        const validateForm = () => {
            const errors = [];
            if(!selectedCategory.value) {
                errors.push('Izaberi kategoriju');
            }

            if(!basicArticleInfo.name) {
                errors.push('Unesi ime proizvoda');
            }

            if(!basicArticleInfo.price) {
                errors.push('Unesi cenu proizvoda');
            }

            if(!basicArticleInfo.productKey) {
                errors.push('Unesi sifru proizvoda');
            }

            if(!basicArticleInfo.description) {
                errors.push('Unesi opis proizvoda');
            }

            if(!images.value || images.value.length == 0 ) {
                errors.push('Unesi bar jednu sliku');
            }

            if(errors.length == 0)  {
                for(const i in specificationValidators) {
                    if(specificationValidators[i] && specificationValidators[i].isError) {
                        errors.push('Proverite specifikacije')
                        break;
                    }
                }
            }

            if(errors.length > 0) {
                toast.error(errors.join(','));
            }

            return errors.length === 0;
        }

        const removeImage = (imageUrl) => {
            const imageToRemove = images.value.find(im => im.imageUrl === imageUrl);
            if(!imageToRemove.image) {
                imagesToRemove.value.push('images' + imageToRemove.imageUrl.split('images')[1]);
            }

            images.value = images.value.filter(im => im.imageUrl !== imageUrl);
        }

        const onSpecificationChange = (event, specificationName) => {
            const specificationValue = event.target.value;
            addedSpecifications[specificationName] = specificationValue;

            const validator = specificationValidators[specificationName];

            if(validator && validator.value) {
                validator.isError = false;
                if(event.target.value) {
                    const regex = new RegExp(validator.value);
                    validator.isError = !regex.test(event.target.value);
                }
            }
        }

        const displayToManyImagesError = () => {
            toast.error("Možete uneti najviše 6 slika");
        }

        return {
            categories,
            childCategories,
            selectedCategory,
            selectedSubCategory,
            selectCategory,
            isEditMode,
            addedSpecifications,
            specificationValidators,
            onFileSelected,
            images,
            removeImage,
            createProduct,
            ...toRefs(basicArticleInfo),
            onCategoryChange,
            onSubCategoryChange,
            onSpecificationChange,
            openActionModal,
            saveAction,
            cancelActionModal,
            isActionModalOpen,
            discountPrice,
            discountPrecentage,
            deleteAction,
            displayToManyImagesError
        }
    },
}
</script>

<style scoped lang="scss">
.edit-wrapper {
    width: 50vw;
    margin-left: auto;
    margin-right: auto;
    padding-top: 70px;
    padding-left: $grid-margin-width;

    @include media("<=desktop-medium") { 
        width: 60vw;
    }

    @include media("<=desktop-small") { 
        width: 70vw;
    }

    @include media("<=tablet-large") { 
        width: 70vw;
    }
}

    .m-20 {
        margin-bottom: 20px!important;
    }

    .add-image-span {
        font-size: 32px;
        color: color(darkgrey);
        position: absolute;
        right: 10px;
        top: -4px;
        cursor: pointer;
    }

    .error-div {
        margin-bottom: -4px!important;
        label {
            color: red;
        }

        input {
            border-color: red !important;
        }
    }

        .error-text{
            color: red;
            font-size: 12px;
            top: -4px;
            position: relative
        }

    .save-btn {
        background: color(blue);
        width: 100%;
        color: white;
        border-radius: 0;
        margin-bottom: 20px;

        &:hover {
            color: white;
        }
    }

    .add-action-btn {
        background: color(red);
        width: 100%;
        color: white;
        border-radius: 0;
        margin-bottom: 20px;

        &:hover {
            color: white;
        }
    }

    .image-container {
        position: relative;
    }

        .image-order {
            position: absolute;
            left: 0px;
            top: 0px;
            z-index: 1000;
            background-color: color(blue);
            width: 47px;
            height: 37px;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: bold;
        }

        .delete-image-btn {
            width: 100%;
            background: color(darkgrey);
            color: white;
            border-radius: 0; 
            
            &:hover {
                color: white;
            }
        }

            .delete-icon {
                width: 20px;
                height: 20px;
                @include background-svg($admin-delete-icon);
                margin-right: 7px;
            }
</style>